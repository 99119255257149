import Axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import useForceUpdate from "use-force-update";
import { Dropdown } from "primereact/dropdown";
import { EditText } from "react-edit-text";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { CascadeSelect } from "primereact/cascadeselect";
import Swal from "sweetalert2";
import { Checkbox } from "primereact/checkbox";
import $ from "jquery";
import { API } from "../../../components/constants/api_url";
import { ContextMenu } from 'primereact/contextmenu';
import { Tooltip } from "primereact/tooltip";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import { RadioButton } from "primereact/radiobutton";
import { InputTextarea } from 'primereact/inputtextarea'
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Tag } from "primereact/tag";
import { Stationary_Combustion, Fugitive, Business_Travel, Mobile_Combustion, Purchase_Goods_Services, BP8EQ2, BP2LQ3, BP2LQ5, Stationary_Combustion_, Fugitive_, Mobile_Combustion_, Purchase_Goods_Services_, GR2LQ1, GR2LQ2, GR2LQ3, Business_Travel_, HotelStay, Electricity, Employee_Category, Diversity_of_Employees, Employee_Category_Diversity_STT, Total_No_of_Employee_Left_STT, Total_No_of_New_Employee_STT, Total_No_of_Employee_Hire_TurnOver_STT } from "../../../../src/pages/hardcodedforms";
import { Editor } from "primereact/editor";
import { hardcoded } from "../../../../src/pages/constants/hardcodedid";
import APIServices from "../../../service/APIService";
import { Sticky, StickyContainer } from "react-sticky";
import { DateTime } from "luxon";

window.jQuery = $;
window.$ = $;

const DCFInputEntrySubmissionPreview = () => {
    const selector = useSelector((state) => state.user.userdetail);
    const [data, setData] = useState([])
    const [sitelist, setSiteList] = useState([])
    const navigate = useHistory()
    const forceUpdate = useForceUpdate();
    const { id } = useParams();
    const params = useLocation()
    const userList = useSelector(state => state.userlist.userList)
    // const hardcodeddcf = ['10', '11', '36', '15', '16', '188','195','196']
    let std = params.state.standard
    useEffect(async () => {
        console.log(params.state)

        let uriString = {
            "include": [{ "relation": "locationTwos", "scope": { "include": [{ "relation": "locationThrees" }] } }]

        }

        let site_url = API.LocationOne_UP(selector.clientId) + `?filter=${encodeURIComponent(JSON.stringify(uriString))}`;

        APIServices.get(API.DCF_Edit(id)).then((res) => {

            res.data.data1 = params.state.response
            setData(res.data)


        })


        APIServices.get(site_url).then((res) => {
            let site_list = []
            res.data.forEach((country) => {
                if (country.locationTwos !== undefined) {
                    country.locationTwos.forEach((city) => {
                        if (city.locationThrees !== undefined) {
                            city.locationThrees.forEach((site) => {

                                site_list.push({ name: site.name + ' (' + city.name + ')', id: site.id, country: { id: country.id, name: country.name }, city: { id: city.id, name: city.name } })
                            })
                        }
                    })
                }
            })
            console.log(site_list, params.state.site)
            setSiteList(site_list)
        })

    }, [selector]);

    const getRP = (months) => {
        console.log(months)
        if (months.includes('to')) {

            let startDate = moment(months.split('to')[0].trim())
            let endDate = moment(months.split('to')[1].trim())
            let rp = []
            while (startDate <= endDate) {

                rp.push(startDate.format('MM-YYYY'));
                startDate.add(1, 'month');


            }
            return rp
        } else {
            return [moment(months).format('MM-YYYY')]
        }
    }
    const getUser = (id) => {
        let user_name = 'Not Found'
        let index = userList.findIndex(i => i.id === id)
        if (index !== -1) {
            user_name = userList[index].information.empname
        }
        return user_name
    }
    const onCheckBoxSelected = (item, cbind) => {
        console.log(item)
        item.values.map((items, ind) => {
            if (ind === cbind) {

                items.selected = !items.selected
            }
        })
        forceUpdate()
    }
    const onRadioButtonSelected = (item, cbind) => {
        console.log(item)
        item.values.map((items, ind) => {
            if (ind === cbind) {

                items.selected = true
            } else {
                items.selected = false
            }
        })
        forceUpdate()
    }
    const onDateSelected = (item, val) => {

        item.value = val;
        forceUpdate()
    }
    const onNumberChange = (item, val) => {
        item.value = val;
        forceUpdate()
    }

    const onChangeDropwdown = (item, val) => {
        item.value = val;
        item.values.forEach((i) => {
            if (i.value === val) {
                i.selected = true
            } else {
                i.selected = false
            }
        })
        forceUpdate()
    }
    const renderItems = (item, index) => {
        if (item.type === 'checkbox-group') {

            return (
                <div className="flex flex-wrap  gap-3  grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 text-justify fs-16 fw-5'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')}
                        {item.description !== undefined && item.description.trim().length !== 0 && <i style={{

                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={item.description}


                        > help</i>} </label>
                    <div className="col-5">
                        {item.values.map((cb, cbind) => {
                            return (
                                <div className="flex text-justify fs-14 fw-5" style={{ marginBottom: 10 }}>
                                    <Checkbox disabled inputId={"cb" + index + cbind} name={cb.label} value={cb.value} onChange={(e) => { onCheckBoxSelected(item, cbind) }} checked={cb.selected} />
                                    <label htmlFor={"cb" + index + cbind} className="ml-2">{cb.label}</label>
                                </div>
                            )
                        })

                        }
                    </div>

                </div>
            )
        } else if (item.type === 'date') {

            return (
                <div className="flex flex-wrap  gap-3  grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fs-16 fw-5 text-justify'> {item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')}
                        {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{

                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>
                    <Calendar disabled className="col-5 fs-14 fw-4" value={item.value !== null ? moment(item.value).toDate() : null} onChange={(e) => { onDateSelected(item, e.value) }} />
                </div>
            )
        } else if (item.type === 'number') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fs-16 fw-5 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{

                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>
                    <div className="col-5 fs-14 fw-4" >
                        <InputText disabled type='number' onWheel={(e) => e.target.blur()} keyfilter="num" style={{ width: '100%' }} value={item.value} onChange={(e) => { onNumberChange(item, parseFloat(e.target.value), 'as') }} />

                    </div>
                </div>
            )
        } else if (item.type === 'paragraph') {
            return (
                <div className="flex flex-wrap  gap-3 fs-16 fw-5 text-justify justify-content-center" style={{ padding: 10 }}>

                    <label >{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')}</label>

                </div>
            )
        } else if (item.type === 'radio-group') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fs-16 fw-5 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{

                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>
                    <div className="col-5 grid" style={{ padding: 10 }} >
                        {item.values.map((cb, cbind) => {
                            return (
                                <div className="p-2 flex text-justify fs-14 fw-5 align-items-center" >
                                    <RadioButton disabled inputId={"rg" + index + cbind} name={cb.label} value={cb.value} onChange={(e) => onRadioButtonSelected(item, cbind)} checked={cb.selected === true} />

                                    <label htmlFor={"rg" + index + cbind} className="ml-2">{cb.label}</label>
                                </div>
                            )
                        })

                        }
                    </div>

                </div>
            )
        } else if (item.type === 'select') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fw-5 fs-16 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>


                    <div className="col-5 fw-4 fs-14">
                        <Dropdown disabled options={item.values} style={{ width: '100%' }} optionLabel='label' optionValue="value" value={item.value} onChange={(e) => { onChangeDropwdown(item, e.value) }} />
                    </div>

                </div>
            )
        } else if (item.type === 'text') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fs-16 fw-5 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>
                    <div className="col-5 fs-14 fw-4" >
                        <InputText disabled style={{ width: '100%' }} value={item.value} onChange={(e) => { onNumberChange(item, e.target.value) }} />

                    </div>
                </div>
            )
        } else if (item.type === 'textarea') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fs-16 fw-5 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={item.description}

                        > help</i></span>} </label>
                    <div className="col-5 " >
                        <div style={{ width: '100%', resize: 'none', margin: 10 }} className='ql-editor' dangerouslySetInnerHTML={{ __html: item.value }} />

                    </div>

                </div>
            )
        } else if (item.type === 'file') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ marginBottom: 15, padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} />
                    <label style={{ display: 'flex' }} className='col-5 fw-5 fs-16 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={item.description}
                            data-pr-position="right"
                            data-pr-at="right+5 top"
                            data-pr-my="left center-2"> help</i></span>} </label>
                    <div className="col-5" >

                        {item.value !== undefined && item.value.length !== 0 &&
                            <div className="col-12" style={{
                                maxHeight: 300,
                                overflow: 'auto',
                                border: '1px solid #ced4da'
                            }} >
                                <div style={{
                                    border: '1px solid #6366F170',
                                    borderRadius: '10px'
                                }}>
                                    {item.value.map((file, findex) => {

                                        return (
                                            <>
                                                <div style={{
                                                    display: 'flex',
                                                    alignItems: 'center', margin: 5
                                                }} >
                                                    <div className="flex align-items-center " style={{ width: '60%' }}>
                                                        {(file.extension === '.pdf' || file.extension === '.PDF') ?
                                                            <div>
                                                                <iframe src={API.Docs + file.originalname} /> </div> :
                                                            <img alt={file.originalname} role="presentation" src={API.Docs + file.originalname} width={100} style={{ borderRadius: 10 }} />}
                                                        <span className="flex flex-column text-left ml-3">
                                                            {file.originalname}
                                                            <small>{new Date().toLocaleDateString()}</small>
                                                        </span>
                                                    </div>
                                                    <Tag value={'View'} onClick={() => { window.open(API.Docs + file.originalname) }} style={{ width: '20%' }} severity="warning" className="px-3 py-2" />

                                                </div>

                                            </>
                                        )
                                    })

                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
            )
        }

    }
    const checkResponse = () => {
        let result = 0
        let total = data.data1.filter((i) => { return i.required === true }).length

        data.data1.forEach((item) => {


            if (item.type === 'checkbox-group' && item.required === true) {


                if (item.values.filter((i) => { return i.selected }).length !== 0) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            } else if (item.type === 'date' && item.required) {

                if (item.value !== undefined && item.value !== null) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }
            else if (item.type === 'number' && item.required) {

                if (item.value !== undefined && item.value.trim().length !== 0) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }

            else if (item.type === 'radio-group' && item.required) {

                if (item.values.filter((i) => { return i.selected }).length === 1) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }
            else if (item.type === 'select' && item.required) {

                if (item.values.filter((i) => { return i.selected }).length === 1) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }
            else if (item.type === 'text' && item.required) {

                if (item.value !== undefined && item.value.trim().length !== 0) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }
            else if (item.type === 'textarea' && item.required) {

                if (item.value !== undefined && item.value.trim().length !== 0) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            } else if (item.type === 'file' && item.required) {

                if (item.value.length !== 0) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }


        })

        return result === total
    }
    const makeEmpty = () => {
        let result = 0
        let data_ = JSON.parse(JSON.stringify(data.data1))

        data_.forEach((item) => {


            if (item.type === 'checkbox-group') {
                item.values.forEach((i) => {
                    i.selected = false
                })


            } else if (item.type === 'date') {

                item.value = null
            }
            else if (item.type === 'number') {

                item.result = 0
            }

            else if (item.type === 'radio-group' && item.required) {
                item.values.forEach((i) => {
                    i.selected = false
                })

            }
            else if (item.type === 'select') {

                item.values.forEach((i) => {
                    i.selected = false
                })
            }
            else if (item.type === 'text') {

                item.value = 0
            }
            else if (item.type === 'textarea') {

                item.value = 0
            }


        })

        return data_
    }
    const saveDCF = () => {
        let newObj = {}
        if (checkResponse() || params.state.edit === 1) {
            newObj['dcf'] = data.id
            newObj['site'] = params.state.site
            newObj['reporting_period'] = params.state.reporting_period
            newObj['response'] = data.data1
            newObj['user_type'] = 1
            newObj['type'] = 3
            newObj['reject'] = 0
            newObj['data1'] = []
            newObj['frequencycd'] = params.state.frequency
            newObj['approved_by'] = selector.id
            newObj['approved_on'] = moment.utc()
            if (params.state.id !== undefined) {

                APIServices.patch(API.DCF_Submission_Edit(params.state.id), newObj).then((res) => {
                    Swal.fire({
                        title: "Data Approved Successfully",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            navigate.goBack()
                        }
                    })
                }).catch((e) => {
                    Swal.fire({
                        title: "Something went wrong, try after some time. Contact admin if issue still persist",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            navigate.goBack()
                        }
                    })

                })

            }

        }
        forceUpdate()

    }
    const saveDCF_ = () => {
        let newObj = {}
        if (data.data1.length !== 0 || params.state.edit === 1) {
            newObj['dcf'] = data.id
            newObj['site'] = params.state.site
            newObj['reporting_period'] = params.state.reporting_period
            newObj['response'] = data.data1
            newObj['user_type'] = 1
            newObj['type'] = 3
            newObj['reject'] = 0
            newObj['data1'] = []
            newObj['frequencycd'] = params.state.frequency
            newObj['approved_by'] = selector.id
            newObj['approved_on'] = moment.utc()
            if (params.state.id !== undefined) {

                APIServices.patch(API.DCF_Submission_Edit(params.state.id), newObj).then((res) => {
                    Swal.fire({
                        title: "Data Approved Successfully",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            navigate.goBack()
                        }
                    })
                }).catch((e) => {
                    Swal.fire({
                        title: "Something went wrong, try after some time. Contact admin if issue still persist",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            navigate.goBack()
                        }
                    })

                })

            }

        }
        forceUpdate()

    }
    const draftDCF_ = async () => {
        let newObj = {}

        if (data.data1.length !== 0 || params.state.edit === 1) {
            newObj['dcf'] = data.id
            newObj['site'] = params.state.site
            newObj['reporting_period'] = params.state.reporting_period
            newObj['response'] = data.data1
            newObj['user_type'] = 1
            newObj['type'] = 0

            newObj['reject'] = 1
            newObj['data1'] = []
            newObj['frequencycd'] = params.state.frequency
            newObj['approved_by'] = selector.id
            newObj['rejected_on'] = moment.utc()
            const { value: remarks } = await Swal.fire({
                title: 'Form Rejection',
                input: 'text',
                inputLabel: 'Remarks',
                inputValue: '',

                allowOutsideClick: false,
                showCancelButton: true,
                inputValidator: (value) => {
                    if (!value) {
                        return 'remarks mandatory'
                    }
                }
            })

            if (remarks) {

                if (params.state.id !== undefined) {
                    newObj['data2'] = remarks
                    APIServices.patch(API.DCF_Submission_Edit(params.state.id), newObj).then((res) => {
                        Swal.fire({
                            title: "Submission Returned Successfully",

                            confirmButtonText: 'Exit',
                            allowOutsideClick: false,
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                navigate.goBack()
                            }
                        })
                    }).catch((e) => {
                        Swal.fire({
                            title: "Something went wrong, try after some time. Contact admin if issue still persist",

                            confirmButtonText: 'Exit',
                            allowOutsideClick: false,
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                navigate.goBack()
                            }
                        })

                    })

                }
            }


        }
        forceUpdate()

    }
    const draftDCF = async () => {
        let newObj = {}
        console.log(checkResponse(), params.state.edit)
        if (checkResponse() || params.state.edit === 1) {
            newObj['dcf'] = data.id
            newObj['site'] = params.state.site
            newObj['reporting_period'] = params.state.reporting_period
            newObj['response'] = data.data1
            newObj['user_type'] = 1
            newObj['type'] = 0

            newObj['reject'] = 1
            newObj['data1'] = []
            newObj['frequencycd'] = params.state.frequency
            newObj['approved_by'] = selector.id
            newObj['rejected_on'] = moment.utc()
            const { value: remarks } = await Swal.fire({
                title: 'Form Rejection',
                input: 'text',
                inputLabel: 'Remarks',
                inputValue: '',

                allowOutsideClick: false,
                showCancelButton: true,
                inputValidator: (value) => {
                    if (!value) {
                        return 'remarks mandatory'
                    }
                }
            })

            if (remarks) {

                if (params.state.id !== undefined) {
                    newObj['data2'] = remarks
                    APIServices.patch(API.DCF_Submission_Edit(params.state.id), newObj).then((res) => {
                        Swal.fire({
                            title: "Submission Returned Successfully",

                            confirmButtonText: 'Exit',
                            allowOutsideClick: false,
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                navigate.goBack()
                            }
                        })
                    }).catch((e) => {
                        Swal.fire({
                            title: "Something went wrong, try after some time. Contact admin if issue still persist",

                            confirmButtonText: 'Exit',
                            allowOutsideClick: false,
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                navigate.goBack()
                            }
                        })

                    })

                }
            }


        }
        forceUpdate()

    }

    const checkHardcoded = () => {
        if (hardcoded.dcf.includes(id) || hardcoded.dcf2.includes(id)) {
            return true
        } else {
            return false
        }

    }
    const getRPMonth = (item) => {
        if (item.length !== 0) {
            if (item.length >= 2) {
                console.log(item)
                return moment(item[0], 'MM-YYYY').format('MMM-YYYY') + ' to ' + moment(item[item.length - 1], 'MM-YYYY').format('MMM-YYYY')
            } else {
                return moment(item[0], 'MM-YYYY').format('MMM-YYYY')
            }
        }
    }
    const getDate = () => {

        if (params.state.type === 0 && params.state.reject !== 1) {
            return params.state.modified_on
        } else if (params.state.type === 0 && params.state.reject === 1) {
            return params.state.rejected_on
        } else if (params.state.type === 3) {
            return params.state.approved_on
        }
    }
    return (
        <div className="grid" style={{ margin: 10 }}>
            <StickyContainer>
                <div className="col-12">
                    {(selector.id !== undefined && data.length !== 0) ?
                        <div >

                            <Sticky topOffset={0}>
                                {({ style, isSticky }) => (<div style={{ ...style, top: 98, background: isSticky && 'white', zIndex: 100 }}>
                                    <div className="fs-20  fw-7 clr-gray-900">
                                        <h4><span className="mr-2">{'DCF ' + id}</span> {data.title}</h4>
                                    </div>

                                    {/* {data.comments.trim().length !== 0 &&
                            <div className="p-card fw-5 fs-16" style={{ padding: 10, marginBottom: 20 }}>
                                <span style={{ fontWeight: 'bold' }}>Note :&nbsp;</span> {data.comments}
                            </div>} */}
                                    <div className="bg-white" style={{ padding: 24, borderBottom: '1px solid #E0E0E0', borderRadius: 4 }} >
                                        <div className="grid col-12">
                                            <div className="col-4 fs-16" style={{ flexDirection: 'row' }}>         <span className="clr-gray-3 fw-4">Reporting period:&nbsp;</span> <span className="clr-gray-900 fw-7"> {getRPMonth(params.state.reporting_period)}   </span>  </div>
                                            <div className="col-4 fs-16" style={{ flexDirection: 'row' }}>          <span className="clr-gray-3 fw-4">Site:&nbsp;</span> <span className="clr-gray-900 fw-7"> {params.state !== undefined && sitelist.length !== 0 && sitelist.filter((i) => { return i.id === params.state.site })[0].name} </span>  </div>
                                            <div className="col-4 fs-16" style={{ flexDirection: 'row' }}>         <span className="clr-gray-3 fw-4">{params.state.type === 3 ? 'Approved on :' : (params.state.type === 1 && params.state.rejected_on === null) ? 'Submitted on :' : (params.state.type === 1 && params.state.rejected_on !== null) ? 'Resubmitted on :' : params.state.reject === 1 ? 'Returned on :' : 'Drafted on:'}&nbsp;</span> <span className="clr-gray-900 fw-7"> {moment(getDate()).local().format('DD MMM YYYY, hh:mm A')}    </span>  </div>

                                            {/* <div className="col-3 fs-16" style={{ flexDirection: 'row' }}>          <span className="clr-gray-3 fw-4">Version:&nbsp;</span> <span className="clr-gray-900 fw-7">{moment(data.updated).local().format('DD MMM YYYY, hh:mm A')}</span>  </div> */}
                                        </div>

                                    </div>
                                </div>)}
                            </Sticky>
                            {(data.data1.length !== 0 && !checkHardcoded()) ?
                                <div>
                                    <div className="bg-white" style={{ borderRadius: 4 }}>

                                        <div style={{ padding: 24 }}>
                                            {data.data1.map((item, index) => {

                                                return renderItems(item, index)
                                            })

                                            }
                                        </div>
                                        <div style={{ borderTop: '1px solid #E0E0E0' }} />
                                        <div className="grid" style={{ padding: 24 }} >
                                            <label className="col-5 fw-7 fs-16">Comments</label>
                                            <div className="col-12" style={{ maxHeight: 300, overflowY: 'scroll', overflow: 'auto' }}>
                                                {params.state !== undefined && params.state.return_remarks !== null &&
                                                    params.state.return_remarks.map((cmnt) => {
                                                        return (
                                                            <div className="col-12 grid" style={{ marginBottom: 10, borderBottom: '1px solid gray' }}>
                                                                <div className="col-5">
                                                                    <div>   {cmnt.user_id === selector.id ? 'You' : getUser(cmnt.user_id)}</div>
                                                                    <div className="mt-2" >     {DateTime.fromISO(cmnt.created_on, { zone: 'utc' }).toLocal().toFormat('dd-LLL-yyyy')} </div>
                                                                </div>
                                                                <div className="col-5">
                                                                    {cmnt.remarks}
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>


                                        </div>
                                        <div style={{ marginTop: 20 }}>
                                            <img src={require('../../../assets/dummy/user_placeholder.jpeg').default} style={{ width: '100%' }} />
                                        </div>
                                    </div>
                                    <div style={{ position: 'sticky', bottom: 0, zIndex: 100 }}>
                                        <div className="flex justify-content-end" style={{ padding: 10, background: 'white' }}>
                                            <Button label='Close' className="ml-4" onClick={() => { navigate.goBack() }} ></Button>

                                        </div>
                                    </div>
                                </div>
                                : hardcoded.dcf.includes(id) ?
                                    <div >
                                        <div className="bg-white" style={{ padding: 24 }}>
                                            <>
                                                {

                                                    (id === '10') ?
                                                        <div>
                                                            <Fugitive_ standard={std} data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                        </div>
                                                        :
                                                        (id === '11') ?
                                                            <div>
                                                                <Stationary_Combustion_ standard={std}  data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                            </div>
                                                            :
                                                            (id === '36') ?
                                                                <div>
                                                                    < Business_Travel_ standard={std} data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                                </div>

                                                                :
                                                                (id === '15') ?
                                                                    <div>
                                                                        <Mobile_Combustion_ standard={std} data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                                    </div>

                                                                    :
                                                                    (id === '16') ?
                                                                        <div>
                                                                            <Purchase_Goods_Services_ standard={std} data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                                        </div>
                                                                        : id === '188' ?
                                                                            <div>
                                                                                <BP8EQ2 data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                                            </div>
                                                                            : id === '195' ?
                                                                                <div>
                                                                                    <BP2LQ3 data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                                                </div>
                                                                                : id === '196' ?
                                                                                    <div>
                                                                                        <BP2LQ5 data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                                                    </div>
                                                                                    : id === '245' ?
                                                                                        <div>
                                                                                            <GR2LQ1 data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                                                        </div>
                                                                                        : id === '246' ?
                                                                                            <div>
                                                                                                <GR2LQ2 data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                                                            </div>
                                                                                            : id === '247' ?
                                                                                                <div>
                                                                                                    <GR2LQ3 data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                                                                </div>
                                                                                                : id === '254' ?
                                                                                                    <div>
                                                                                                        <HotelStay standard={std} data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                                                                    </div>
                                                                                                    : id === '257' ?
                                                                                                        <div>
                                                                                                            <Electricity standard={std} data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                                                                        </div> : 'Contact Admin'



                                                }</>
                                            <div style={{ borderTop: '1px solid #E0E0E0' }} />
                                            <div className="bg-white grid" style={{ paddingTop: 24 }} >
                                                <label className="col-5 fw-7 fs-16">Comments</label>
                                                <div className="col-12" style={{ maxHeight: 300, overflowY: 'scroll', overflow: 'auto' }}>
                                                    {params.state !== undefined && params.state.return_remarks !== null &&
                                                        params.state.return_remarks.map((cmnt) => {
                                                            return (
                                                                <div className="col-12 grid" style={{ marginBottom: 10, borderBottom: '1px solid gray' }}>
                                                                    <div className="col-5">
                                                                        <div>   {cmnt.user_id === selector.id ? 'You' : getUser(cmnt.user_id)}</div>
                                                                        <div className="mt-2" >     {DateTime.fromISO(cmnt.created_on, { zone: 'utc' }).toLocal().toFormat('dd-LLL-yyyy')} </div>
                                                                    </div>
                                                                    <div className="col-5">
                                                                        {cmnt.remarks}
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>

                                            </div>
                                            <div style={{ marginTop: 20 }}>
                                                <img src={require('../../../assets/dummy/user_placeholder.jpeg').default} style={{ width: '100%' }} />
                                            </div>
                                        </div>

                                        <div style={{ position: 'sticky', bottom: 0, zIndex: 100 }}>
                                            <div className="flex justify-content-end" style={{ padding: 10, background: 'white' }}>
                                                <Button className="ml-4" label='Close' onClick={() => { navigate.goBack() }} ></Button>

                                            </div></div>
                                    </div>
                                    : id === '262' ?
                                        <div>
                                            <div className="bg-white" style={{ padding: 24 }}>
                                                <Employee_Category data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                <div style={{ borderTop: '1px solid #E0E0E0' }} />
                                                <div className="bg-white grid" style={{ paddingTop: 24 }} >
                                                    <label className="col-5 fw-7 fs-16">Comments</label>
                                                    <div className="col-12" style={{ maxHeight: 300, overflowY: 'scroll', overflow: 'auto' }}>
                                                        {params.state !== undefined && params.state.return_remarks !== null &&
                                                            params.state.return_remarks.map((cmnt) => {
                                                                return (
                                                                    <div className="col-12 grid" style={{ marginBottom: 10, borderBottom: '1px solid gray' }}>
                                                                        <div className="col-5">
                                                                            <div>   {cmnt.user_id === selector.id ? 'You' : getUser(cmnt.user_id)}</div>
                                                                            <div className="mt-2" >     {DateTime.fromISO(cmnt.created_on, { zone: 'utc' }).toLocal().toFormat('dd-LLL-yyyy')} </div>
                                                                        </div>
                                                                        <div className="col-5">
                                                                            {cmnt.remarks}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>


                                                </div>
                                                <div style={{ marginTop: 20 }}>
                                                    <img src={require('../../../assets/dummy/user_placeholder.jpeg').default} style={{ width: '100%' }} />
                                                </div>
                                            </div>
                                            <div style={{ position: 'sticky', bottom: 0, zIndex: 100 }}>
                                                <div className="flex justify-content-end" style={{ padding: 10, background: 'white' }}>
                                                    <Button className="ml-4" label='Close' onClick={() => { navigate.goBack() }} ></Button>
                                                </div>
                                            </div>
                                        </div> : id === '263' ?
                                        <div>
                                            <div className="bg-white" style={{ padding: 24 }}>
                                                <Diversity_of_Employees data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                <div style={{ borderTop: '1px solid #E0E0E0' }} />
                                                <div className="bg-white grid" style={{ paddingTop: 24 }} >
                                                    <label className="col-5 fw-7 fs-16">Comments</label>
                                                    <div className="col-12" style={{ maxHeight: 300, overflowY: 'scroll', overflow: 'auto' }}>
                                                        {params.state !== undefined && params.state.return_remarks !== null &&
                                                            params.state.return_remarks.map((cmnt) => {
                                                                return (
                                                                    <div className="col-12 grid" style={{ marginBottom: 10, borderBottom: '1px solid gray' }}>
                                                                        <div className="col-5">
                                                                            <div>   {cmnt.user_id === selector.id ? 'You' : getUser(cmnt.user_id)}</div>
                                                                            <div className="mt-2" >     {DateTime.fromISO(cmnt.created_on, { zone: 'utc' }).toLocal().toFormat('dd-LLL-yyyy')} </div>
                                                                        </div>
                                                                        <div className="col-5">
                                                                            {cmnt.remarks}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div style={{ marginTop: 20 }}>
                                                    <img src={require('../../../assets/dummy/user_placeholder.jpeg').default} style={{ width: '100%' }} />
                                                </div>
                                            </div>
                                            <div style={{ position: 'sticky', bottom: 0, zIndex: 100 }}>
                                                <div className="flex justify-content-end" style={{ padding: 10, background: 'white' }}>
                                                    <Button className="ml-4" label='Close' onClick={() => { navigate.goBack() }} ></Button>
                                                </div>
                                            </div>
                                        </div> : id === '275' ?
                                        <div>
                                            <div className="bg-white" style={{ padding: 24 }}>
                                                <Employee_Category_Diversity_STT data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                <div style={{ borderTop: '1px solid #E0E0E0' }} />
                                                <div className="bg-white grid" style={{ paddingTop: 24 }} >
                                                    <label className="col-5 fw-7 fs-16">Comments</label>
                                                    <div className="col-12" style={{ maxHeight: 300, overflowY: 'scroll', overflow: 'auto' }}>
                                                        {params.state !== undefined && params.state.return_remarks !== null &&
                                                            params.state.return_remarks.map((cmnt) => {
                                                                return (
                                                                    <div className="col-12 grid" style={{ marginBottom: 10, borderBottom: '1px solid gray' }}>
                                                                        <div className="col-5">
                                                                            <div>   {cmnt.user_id === selector.id ? 'You' : getUser(cmnt.user_id)}</div>
                                                                            <div className="mt-2" >     {DateTime.fromISO(cmnt.created_on, { zone: 'utc' }).toLocal().toFormat('dd-LLL-yyyy')} </div>
                                                                        </div>
                                                                        <div className="col-5">
                                                                            {cmnt.remarks}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div style={{ marginTop: 20 }}>
                                                    <img src={require('../../../assets/dummy/user_placeholder.jpeg').default} style={{ width: '100%' }} />
                                                </div>
                                            </div>
                                            <div style={{ position: 'sticky', bottom: 0, zIndex: 100 }}>
                                                <div className="flex justify-content-end" style={{ padding: 10, background: 'white' }}>
                                                    <Button className="ml-4" label='Close' onClick={() => { navigate.goBack() }} ></Button>
                                                </div>
                                            </div>
                                        </div> : id === '277' ?
                                        <div>
                                            <div className="bg-white" style={{ padding: 24 }}>
                                                <Total_No_of_New_Employee_STT data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                <div style={{ borderTop: '1px solid #E0E0E0' }} />
                                                <div className="bg-white grid" style={{ paddingTop: 24 }} >
                                                    <label className="col-5 fw-7 fs-16">Comments</label>
                                                    <div className="col-12" style={{ maxHeight: 300, overflowY: 'scroll', overflow: 'auto' }}>
                                                        {params.state !== undefined && params.state.return_remarks !== null &&
                                                            params.state.return_remarks.map((cmnt) => {
                                                                return (
                                                                    <div className="col-12 grid" style={{ marginBottom: 10, borderBottom: '1px solid gray' }}>
                                                                        <div className="col-5">
                                                                            <div>   {cmnt.user_id === selector.id ? 'You' : getUser(cmnt.user_id)}</div>
                                                                            <div className="mt-2" >     {DateTime.fromISO(cmnt.created_on, { zone: 'utc' }).toLocal().toFormat('dd-LLL-yyyy')} </div>
                                                                        </div>
                                                                        <div className="col-5">
                                                                            {cmnt.remarks}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div style={{ marginTop: 20 }}>
                                                    <img src={require('../../../assets/dummy/user_placeholder.jpeg').default} style={{ width: '100%' }} />
                                                </div>
                                            </div>
                                            <div style={{ position: 'sticky', bottom: 0, zIndex: 100 }}>
                                                <div className="flex justify-content-end" style={{ padding: 10, background: 'white' }}>
                                                    <Button className="ml-4" label='Close' onClick={() => { navigate.goBack() }} ></Button>
                                                </div>
                                            </div>
                                        </div> : id === '278' ?
                                        <div>
                                            <div className="bg-white" style={{ padding: 24 }}>
                                                <Total_No_of_Employee_Left_STT data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                <div style={{ borderTop: '1px solid #E0E0E0' }} />
                                                <div className="bg-white grid" style={{ paddingTop: 24 }} >
                                                    <label className="col-5 fw-7 fs-16">Comments</label>
                                                    <div className="col-12" style={{ maxHeight: 300, overflowY: 'scroll', overflow: 'auto' }}>
                                                        {params.state !== undefined && params.state.return_remarks !== null &&
                                                            params.state.return_remarks.map((cmnt) => {
                                                                return (
                                                                    <div className="col-12 grid" style={{ marginBottom: 10, borderBottom: '1px solid gray' }}>
                                                                        <div className="col-5">
                                                                            <div>   {cmnt.user_id === selector.id ? 'You' : getUser(cmnt.user_id)}</div>
                                                                            <div className="mt-2" >     {DateTime.fromISO(cmnt.created_on, { zone: 'utc' }).toLocal().toFormat('dd-LLL-yyyy')} </div>
                                                                        </div>
                                                                        <div className="col-5">
                                                                            {cmnt.remarks}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div style={{ marginTop: 20 }}>
                                                    <img src={require('../../../assets/dummy/user_placeholder.jpeg').default} style={{ width: '100%' }} />
                                                </div>
                                            </div>
                                            <div style={{ position: 'sticky', bottom: 0, zIndex: 100 }}>
                                                <div className="flex justify-content-end" style={{ padding: 10, background: 'white' }}>
                                                    <Button className="ml-4" label='Close' onClick={() => { navigate.goBack() }} ></Button>
                                                </div>
                                            </div>
                                        </div>  : id === '278' &&
                                        <div>
                                            <div className="bg-white" style={{ padding: 24 }}>
                                                <Total_No_of_Employee_Hire_TurnOver_STT data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                <div style={{ borderTop: '1px solid #E0E0E0' }} />
                                                <div className="bg-white grid" style={{ paddingTop: 24 }} >
                                                    <label className="col-5 fw-7 fs-16">Comments</label>
                                                    <div className="col-12" style={{ maxHeight: 300, overflowY: 'scroll', overflow: 'auto' }}>
                                                        {params.state !== undefined && params.state.return_remarks !== null &&
                                                            params.state.return_remarks.map((cmnt) => {
                                                                return (
                                                                    <div className="col-12 grid" style={{ marginBottom: 10, borderBottom: '1px solid gray' }}>
                                                                        <div className="col-5">
                                                                            <div>   {cmnt.user_id === selector.id ? 'You' : getUser(cmnt.user_id)}</div>
                                                                            <div className="mt-2" >     {DateTime.fromISO(cmnt.created_on, { zone: 'utc' }).toLocal().toFormat('dd-LLL-yyyy')} </div>
                                                                        </div>
                                                                        <div className="col-5">
                                                                            {cmnt.remarks}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div style={{ marginTop: 20 }}>
                                                    <img src={require('../../../assets/dummy/user_placeholder.jpeg').default} style={{ width: '100%' }} />
                                                </div>
                                            </div>
                                            <div style={{ position: 'sticky', bottom: 0, zIndex: 100 }}>
                                                <div className="flex justify-content-end" style={{ padding: 10, background: 'white' }}>
                                                    <Button className="ml-4" label='Close' onClick={() => { navigate.goBack() }} ></Button>
                                                </div>
                                            </div>
                                        </div>
                            }


                        </div>
                        :
                        <div className="col-12 card">Form not found</div>
                        // <div className="col-12 card">You have no rights to access this page </div>

                    }
                </div>
            </StickyContainer>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(DCFInputEntrySubmissionPreview, comparisonFn);
