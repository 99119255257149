import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { resetLoggedUserDetail } from './RTK/Login/userProfile';
import { motion } from "framer-motion";
import { resetOverallPendingData } from './RTK/Background/pendingData';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import useForceUpdate from 'use-force-update';
export const AppTopbar = (props) => {
    const selector = useSelector(state => state.user.userdetail)
    const [rotation, setRotation] = useState(true)
    const history = useHistory()
    const dispatch = useDispatch()
    const login_data = useSelector((state) => state.user.userdetail)
    const location = useLocation()
    const navigate = useHistory()
    const forceUpdate = useForceUpdate()
    const client_info = useSelector((state) => state.userlist.admindetail)
    const [pathname, setPathname] = useState(null)
    useEffect(() => {
        setPathname(location.pathname)

    }, [location.pathname])
    return (
        <div className="layout-topbar justify-content-between">
            <div className=' flex align-items-center justify-content-start'>

                <div className='grid'>
                    <div className='flex  align-items-start' style={{ flexDirection: 'column' }} >

                        <div className='flex justify-content-end align-items-center mr-3' style={{ flexDirection: 'column' }}>
                            <img id='clientlogo' src={require('../src/assets/images/stt-logo.svg').default} style={{
                                width: '100%', maxWidth: 200, padding: 10

                            }} />

                        </div>
                    </div>


                    <>

                        {Object.keys(selector).length !== 0 &&
                            <>
                                {/* <label className='mr-4'>{login_data.role === 'clientuser' ? login_data.information.empname : login_data.information.companyname}</label> */}
                                {/* <button style={{ marginRight: 40 }} onClick={() => { dispatch(resetLoggedUserDetail()); dispatch(resetOverallPendingData([])); localStorage.clear(); sessionStorage.clear(); history.push('/') }} className='p-link'><i className='pi pi-sign-out clr-delete' style={{ fontSize: 20 }} ></i> </button> */}

                            </>

                        }
                    </>

                </div>



            </div>
            <hr style={{
                borderRight: '2px solid lightgray',
                height: '50%'
            }} />
            <div className='col-4 fw-7 fs-18'>
                Environmental, Social and Governance
            </div>
            <div className='col-6 flex justify-content-end'>
                <Link style={{ width: 200 }} className="justify-content-center layout-topbar-logo mr-2">
                    <img id='logo' width={'100%'} height={'auto'} src={props.layoutColorMode === 'light' ? require('../src/assets/images/eisqr_logo_final_v1.png').default : require('../src/assets/images/eisqr_logo.png').default} alt="logo" />

                </Link>
            </div>






        </div>
    );
}
