import React, { useEffect, useState } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import { motion } from "framer-motion";
import useForceUpdate from "use-force-update";
import LazyView from "../../../components/LazyView";
import { DateTime } from "luxon";
import moment from "moment";
import { API } from "../../../components/constants/api_url";
import APIServices from "../../../service/APIService";
import { useSelector } from "react-redux";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
const ApproverOverdue = () => {

    const params = useLocation()
    const navigate = useHistory()
    const [data, setData] = useState([])
    const [rawrf, setRawRF] = useState([])
    const [filterdcf, setFilterDCF] = useState([])
    const [done, setDone] = useState(false)
    const [searchstr, setSearchStr] = useState('')

    const userList = useSelector(state => state.userlist.userList)
    const frequency_list = [{ name: 'Monthly', id: 1 }, { name: 'Bi-Monthly', id: 2 }, { name: 'Quartely', id: 3 }, { name: 'Annually', id: 4 }, { name: 'Bi-Annually', id: 5 }, { name: 'Undefined', id: 6 }]
    const [overallmetric, setOverallMetric] = useState([])
    const [list, setList] = useState([])
    const [update, setUpdate] = useState(false)
    const [sitelist, setSiteList] = useState([])
    const login_data = useSelector((state) => state.user.userdetail)
    const client_info = useSelector((state) => state.userlist.admindetail)
    const [rfass, setRFAss] = useState([])
    const [present, setPresent] = useState([])
    const [past, setPast] = useState([])
    const [selectedsite, setSelectedSite] = useState(null)

    const [future, setFuture] = useState([])
    const [dcflist, setDcfList] = useState([])

    const [sitelistall, setSiteListAll] = useState([])
    const [dcfassign, setDCFAssign] = useState([])
    const [submitteddcf, setSubmittedDCF] = useState([])
    const [pendingap, setPendingAP] = useState([])
    const [pendingapbk, setPendingAPBK] = useState([])

    const [response, setResponse] = useState([])
    const [rfresponse, setRFResponse] = useState([])
    const { id } = useParams();
    const forceUpdate = useForceUpdate()

    useEffect(() => {

        if (Object.keys(client_info).length !== 0 && done === false) {
            setDone(true)

            console.log('its working')
            let uriString = {
                "include": [{ "relation": "locationTwos", "scope": { "include": [{ "relation": "locationThrees" }] } }]

            }
            let uriString3 = {
                "include": [{ "relation": "newDataPoints" }]

            }
            let uriString2 = {
                "include": [{ "relation": "newTopics", "scope": { "include": [{ "relation": "newMetrics", "scope": { "include": [{ "relation": "newDataPoints" }] } }] } }]

            }
            let dcf_list = [], dcf_submitted = [], locloc = []
            let site_url = API.LocationOne_UP(login_data.information.cid) + `?filter=${encodeURIComponent(JSON.stringify(uriString))}`;
            const promise1 = APIServices.get(API.DCF)
            const promise2 = APIServices.get(API.DCF_Submit_UP(login_data.information.cid))
            const promise3 = APIServices.get(API.LocationThree)
            let promise4 = APIServices.get(API.RF)
            let promise5 = APIServices.get(API.RF_Submit_UP(login_data.information.cid))
            let promise6 = APIServices.get(API.Report_Name_Twos)
            let promise7 = APIServices.get(API.QL_Listing_Filter_UP(login_data.information.cid))

            let Overall = API.Categories + `?filter=${encodeURIComponent(JSON.stringify(uriString2))}`;

            let url = API.Metric + `?filter=${encodeURIComponent(JSON.stringify(uriString3))}`;
            let promise8 = APIServices.get(Overall)
            let promise9 = APIServices.get(API.RF_User_UP(login_data.information.cid))
            let promise10 = APIServices.get(API.AssignDCFClient_UP(login_data.information.cid))
            Promise.all([promise1, promise2, promise3, promise4, promise5, promise6, promise7, promise8, promise9, promise10]).then(function (values) {

                setRawRF(values[3].data)
                setRFResponse(values[4].data)
                let locs = list

                setRFAss(values[8].data)
                locs.framework = values[5].data.filter((i) => { return client_info.information.report.includes(i.id) })
                setList(locs)
                setResponse(values[7].data)
                let categoryList = [], metricList = [], topicList = [], filter_dcf = []
                let loc = list
                values[7].data.forEach((cat) => {
                    if (cat.newTopics !== undefined) {
                        categoryList.push({ id: cat.id, title: cat.title })
                        cat.newTopics.forEach((topic) => {
                            topic.newMetrics !== undefined && topicList.push({ name: topic.title, title: topic.title + "(" + cat.title + ")", id: topic.id })
                            if (topic.newMetrics !== undefined) {

                                topic.newMetrics.forEach((metric) => {
                                    metricList.push(metric)
                                })
                            }
                        })
                    }


                })
                loc.category = categoryList
                loc.metric = metricList
                loc.topic = topicList

                if (values[9].data.length !== 0) {
                    metricList.forEach((j) => {
                        if (values[9].data[0].metric_ids.includes(j.id)) {
                            if (j.newDataPoints) {
                                j.newDataPoints.forEach((k) => {
                                    if (Array.isArray(k.data1) && k.data1[0].datasource && typeof k.data1[0].datasource === 'number') {
                                        let dcfindex = values[0].data.findIndex((m) => { return m.id === k.data1[0].datasource })
                                        let dcfindex2 = filter_dcf.findIndex((m) => { return m.id === k.data1[0].datasource })

                                        if (dcfindex !== -1 && dcfindex2 === -1) {
                                            filter_dcf.push(values[0].data[dcfindex])
                                        }
                                    }
                                })
                            }
                        }

                    })
                    if (values[9].data[0].cf_ids !== null && values[9].data[0].cf_ids.length !== 0) {
                        values[9].data[0].cf_ids.forEach((id) => {
                            let dcfindex = values[0].data.findIndex((m) => { return m.id === id })
                            if (dcfindex !== -1) {
                                filter_dcf.push(values[0].data[dcfindex])
                            }
                        })
                    }

                }
                setFilterDCF(filter_dcf)
                setList(loc)
                setOverallMetric(JSON.parse(JSON.stringify(metricList)))

                if (values[6].data.length !== 0) {
                    let obj = values[6].data[0]


                } else {
                    loc.metric = []
                }



                dcf_list = values[0].data; setDcfList(values[0].data)

                dcf_submitted = values[1].data;
                locloc = values[2].data
                setSubmittedDCF((prev) => ([...prev, ...values[1].data]))
                forceUpdate()

                APIServices.get(API.AssignDCFUser_UP(login_data.information.cid)).then((res) => {
                    setDCFAssign(JSON.parse(JSON.stringify(res.data)))
                    let filter = [], locationFilter = [0], rawData = [], pending = []
                    let show_ = { entry: res.data.filter((i) => { return i.type === 0 && i.user_id === login_data.id }).length === 0 ? false : true, approver: res.data.filter((i) => { return i.type === 0 && i.approver_id === login_data.id }).length === 0 ? false : true }


                    res.data.forEach((item) => {

                        if (item.type === 0 && item.approver_id === login_data.id && filter_dcf.findIndex(i => { return i.id === item.dcfId }) !== -1) {

                            if (dcf_list.filter((k) => { return k.id === item.dcfId }).length !== 0 && locloc.findIndex((k) => { return k.id === item.site[0] }) !== -1) {
                                if (dcf_submitted.filter((k) => { return k.dcf === item.dcfId && k.site === item.site[0] && (k.type === 1 || k.type === 2 || k.type === 3 || k.reject === 1) }).length !== 0) {
                                    if (pending.findIndex((k) => { return k.id === dcf_submitted.filter((k) => { return k.dcf === item.dcfId && k.site === item.site[0] && (k.type === 1 || k.type === 2 || k.type === 3 || k.reject === 1) })[0].id }) === -1) {
                                        pending.push(...dcf_submitted.filter((k) => { return k.dcf === item.dcfId && k.site === item.site[0] && (k.type === 1 || k.type === 2 || k.type === 3 || k.reject === 1) }))
                                    }
                                    if (locationFilter.findIndex((k) => { return k === item.site[0] }) === -1) {
                                        locationFilter.push(item.site[0])
                                    }
                                }
                            }
                        }

                        if ((parseFloat(id) === 0 || parseFloat(id) === item.site[0]) && item.type === 0 && item.user_id === login_data.id && filter_dcf.findIndex(i => { return i.id === item.dcfId }) !== -1) {
                            console.log(item.user_id)
                            if (dcf_list.filter((k) => { return k.id === item.dcfId }).length !== 0 && locloc.findIndex((k) => { return k.id === item.site[0] }) !== -1) {

                                item.dcf_ = dcf_list.filter((k) => { return k.id === item.dcfId })[0]
                                item.frequency_ = frequency_list.filter((k) => { return k.id === item.frequency })[0]

                                // item.start_date = moment.utc(item.start_date).toDate()
                                filter.push(item)
                                if (locationFilter.findIndex((k) => { return k === item.site[0] }) === -1) {
                                    locationFilter.push(item.site[0])
                                }
                                console.log(DateTime.fromISO(item.start_date, { zone: 'utc' }), DateTime.local(), 'dcf')
                                let st_date = DateTime.fromISO(item.start_date, { zone: 'utc' }).toLocal(), ed_date = DateTime.utc().toLocal()
                                if (typeof item.end_date === 'string') {
                                    ed_date = DateTime.fromISO(item.end_date, { zone: 'utc' }).toLocal()
                                }
                                console.log(st_date, ed_date, typeof item.end_date)
                                console.log(moment(moment.utc(item.start_date).toDate()).diff(moment.utc(moment.utc()), 'days'))
                                //  getMonths(moment(moment.utc(item.start_date).toDate()), moment.utc(moment.utc()), item.frequency, item, dcf_submitted)

                                // getMonthsLuxon(st_date, ed_date, item.frequency, item, dcf_submitted)

                                rawData.push(item)
                            }

                        }
                    })

                    console.log(pending)

                    setPendingAP(pending.filter((i) => { return ((i.type === 1 || i.type === 2) && locationFilter.includes(i.site) && i.form_type !== 2 && (i.site === parseFloat(id) || parseFloat(id) === 0)) }).filter(obj => {
             
                        const lastReportingPeriod = obj.reporting_period[obj.reporting_period.length - 1];
                       
                        const nextMonth = DateTime.fromFormat(lastReportingPeriod, 'MM-yyyy').plus({ months: 1 }).toLocal();
console.log((DateTime.local().diff(nextMonth, 'months').months).toFixed(0))
                        return (DateTime.local().diff(nextMonth, 'months').months).toFixed(0) > 0
                    }))
                    setPendingAPBK(pending.filter((i) => { return ((i.type === 1 || i.type === 2) && locationFilter.includes(i.site) && i.form_type !== 2 && (i.site === parseFloat(id) || parseFloat(id) === 0)) }).filter(obj => {
                        const lastReportingPeriod = obj.reporting_period[obj.reporting_period.length - 1];
                        const nextMonth = DateTime.fromFormat(lastReportingPeriod, 'MM-yyyy').plus({ months: 1 }).toLocal();

                        return (DateTime.local().diff(nextMonth, 'months').months).toFixed(0) > 0
                    }))
                    setData(pending.filter((i) => { return ((i.type === 1 || i.type === 2) && locationFilter.includes(i.site) && i.form_type !== 2 && (i.site === parseFloat(id) || parseFloat(id) === 0)) }).filter(obj => {
                        const lastReportingPeriod = obj.reporting_period[obj.reporting_period.length - 1];
                        const nextMonth = DateTime.fromFormat(lastReportingPeriod, 'MM-yyyy').plus({ months: 1 }).toLocal();

                        return (DateTime.local().diff(nextMonth, 'months').months).toFixed(0) > 0
                    }))
                    APIServices.get(site_url).then((rest) => {
                        let site_list = [{ name: 'All', id: 0 }]
                        let site_list_all = []
                        rest.data.forEach((country) => {
                            if (country.locationTwos !== undefined) {
                                country.locationTwos.forEach((city) => {
                                    if (city.locationThrees !== undefined) {
                                        city.locationThrees.forEach((site) => {
                                            filter.forEach((item) => { if (site.id === item.site[0]) { item.site = site } })
                                            site_list.push({ name: site.name + ' (' + city.name + ')', id: site.id, country: { id: country.id, name: country.name }, city: { id: city.id, name: city.name } })
                                            site_list_all.push({ name: site.name + ' (' + city.name + ')', id: site.id, country: { id: country.id, name: country.name }, city: { id: city.id, name: city.name } })

                                        })
                                    }
                                })
                            }
                        })

                        setSiteList(site_list.filter((k) => { return locationFilter.includes(k.id) }))
                        setSelectedSite(parseFloat(id))
                    })

                })

            })
        }
    }, [client_info])
    useEffect(() => {

        if (pendingap && selectedsite !== null) {
            let loc = JSON.parse(JSON.stringify(data))
            setPendingAPBK(loc.filter((i) => { return (i.site === selectedsite || selectedsite === 0) }))
            setPendingAP(loc.filter((i) => { return (i.site === selectedsite || selectedsite === 0) }))
            forceUpdate()
        }
    }, [selectedsite])
    const verticalText = (str) => {
        let div = document.createElement('div')
        div.style.position = 'absolute'
        div.style.right = '10px'
        div.style.top = '1%'
        div.style.flexDirection = 'column'
        div.style.opacity = 0.5
        div.className = 'flex'

        for (var i = 0; i < str.trim().length; i++) {
            let charc = str.trim()
            let label = document.createElement('label')
            label.innerHTML = charc[i]
            label.style = "color: lightgray; font-weight: 900; font-size: 18px;text-shadow: 1px 2px gray;"
            div.append(label)
        }

        return div.outerHTML
    }
    const getMonthsLuxon = (startDate, endDate, frequency, item, old) => {

        var betweenMonths = [];
        let type = 0, past_ = [], present_ = [], future_ = []

        past_ = JSON.parse(JSON.stringify(past))
        present_ = JSON.parse(JSON.stringify(present))
        future_ = JSON.parse(JSON.stringify(future))

        if (Math.round(startDate.diff(DateTime.utc().startOf('month'), 'days').days).toFixed(0) === 0) {
            let endDate_ = startDate

            let endDate__ = DateTime.utc().toLocal()
            if (frequency === 1) {

                let check = checkSubmission(item.dcf_, item.site, endDate__.toFormat('LLL-yyyy'), old)

                if (check.result) {

                    future_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: endDate__.toFormat('LLL-yyyy'), duedate: endDate__.plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: 0, site: item.site, company_id: login_data.information.cid, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })

                } else {
                    if (check.data) {

                        future_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: endDate__.toFormat('LLL-yyyy'), duedate: endDate__.plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: 0, site: item.site, company_id: login_data.information.cid, frequency })
                    }

                }
            } else if (frequency === 6) {
                let check = checkSubmission(item.dcf_, item.site, endDate__.toFormat('LLL-yyyy'), old)

                if (check.result) {


                    past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: endDate__.toFormat('LLL-yyyy'), duedate: endDate__.toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(endDate__, 'days').days), site: item.site, company_id: login_data.information.cid, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                } else {
                    if (check.data) {

                        past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: endDate__.toFormat('LLL-yyyy'), duedate: endDate__.toFormat('LLL-yyyy'), overdue: 0, site: item.site, type: 6, company_id: login_data.information.cid, frequency })
                    }

                }


            }

        }
        else if (Math.round(startDate.diff(DateTime.utc().startOf('month'), 'days').days) < 31) {
            var date = startDate
            console.log(startDate.diff(endDate.startOf('month'), 'days').days)


            let endDate__ = DateTime.utc().toLocal()
            while (date <= endDate) {

                betweenMonths.push(date.toFormat('LLL-yyyy'));
                date = date.plus({ months: 1 })

            }
            console.log(betweenMonths, item.id)
            if (frequency === 1) {
                console.log(splitArray(betweenMonths, 1), 'SP')
                splitArray(betweenMonths, 1).forEach((months, ind) => {
                    console.log(endDate__.month, DateTime.fromFormat(months[0], 'LLL-yyyy').month, endDate__.year, DateTime.fromFormat(months[0], 'LLL-yyyy').year, item.dcf_, ind, months[0])
                    if (endDate__.month === DateTime.fromFormat(months[0], 'LLL-yyyy').month && endDate__.year === DateTime.fromFormat(months[0], 'LLL-yyyy').year) {
                        let check = checkSubmission(item.dcf_, item.site, months[0], old)

                        if (check.result) {


                            future_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0], duedate: DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: login_data.information.cid, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                        } else {
                            if (check.data) {
                                future_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0], duedate: DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: login_data.information.cid, frequency })

                            }

                        }


                    } else {

                        if (Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy'), 'months').months) === 1) {

                            // if (endDate__ <= moment(months[0]).add(1, 'month').add(4, 'days')) {
                            let check = checkSubmission(item.dcf_, item.site, months[0], old)


                            if (check.result) {


                                present_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0], duedate: DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: login_data.information.cid, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                            } else {
                                if (check.data) {
                                    present_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0], duedate: DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: login_data.information.cid, frequency })

                                }

                            }


                        } else {
                            let check = checkSubmission(item.dcf_, item.site, months[0], old)
                            console.log(check, months[0])
                            if (check.result) {


                                past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0], duedate: DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: login_data.information.cid, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                            } else {
                                if (check.data) {

                                    past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0], duedate: DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: login_data.information.cid, frequency })

                                }

                            }
                        }
                    }

                })
            } else if (frequency === 2) {

                splitArray(betweenMonths, 2).forEach((months) => {
                    if (months.length === 2) {
                        if (endDate__.month === DateTime.fromFormat(months[1], 'LLL-yyyy').month && endDate__.year === DateTime.fromFormat(months[1], 'LLL-yyyy').year) {
                            let check = checkSubmission(item.dcf_, item.site, months[0] + ' to ' + months[1], old)
                            if (check.result) {

                                future_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[1], duedate: DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: login_data.information.cid, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                            } else {
                                if (check.data) {
                                    future_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[1], duedate: DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: login_data.information.cid, frequency })


                                }

                            }


                        } else {
                            if (Math.round(endDate__.diff(DateTime.fromFormat(months[1], 'LLL-yyyy'), 'months').months) === 1) {

                                // if (endDate__ <= moment(months[1]).add(1, 'month').add(4, 'days')) {
                                let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[1]).format('MMM-YYYY'), old)
                                if (check.result) {

                                    present_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[1], duedate: DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: login_data.information.cid, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })

                                } else {
                                    if (check.data) {
                                        present_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[1], duedate: DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: login_data.information.cid, frequency })


                                    }

                                }


                            } else {
                                let check = checkSubmission(item.dcf_, item.site, months[0] + ' to ' + months[1], old)

                                if (check.result) {
                                    past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[1], duedate: DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: login_data.information.cid, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })

                                } else {
                                    if (check.data) {
                                        past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[1], duedate: DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: login_data.information.cid, frequency })

                                    }

                                }
                            }
                        }
                    }
                })
            } else if (frequency === 3) {

                splitArray(betweenMonths, 3).forEach((months) => {
                    if (months.length === 3) {

                        if (endDate__.month === DateTime.fromFormat(months[2], 'LLL-yyyy').month && endDate__.year === DateTime.fromFormat(months[2], 'LLL-yyyy').year) {
                            let check = checkSubmission(item.dcf_, item.site, months[0] + ' to ' + months[2], old)

                            if (check.result) {

                                future_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[2], duedate: DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: login_data.information.cid, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                            } else {
                                if (check.data) {
                                    future_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[2], duedate: DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: login_data.information.cid, frequency })

                                }

                            }


                        } else {
                            if (Math.round(endDate__.diff(DateTime.fromFormat(months[2], 'LLL-yyyy'), 'months').months) === 1) {

                                // if (endDate__ <= moment(months[2]).add(1, 'month').add(4, 'days')) {
                                let check = checkSubmission(item.dcf_, item.site, months[0] + ' to ' + months[2], old)

                                if (check.result) {
                                    present_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[2], duedate: DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: login_data.information.cid, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                                } else {
                                    if (check.data) {
                                        present_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[2], duedate: DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: login_data.information.cid, frequency })

                                    }

                                }



                            } else {

                                let check = checkSubmission(item.dcf_, item.site, months[0] + ' to ' + months[2], old)

                                if (check.result) {
                                    past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[2], duedate: DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: login_data.information.cid, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                                } else {
                                    if (check.data) {
                                        console.log(item.site, item.dcf_, months[0] + ' to ' + months[2])
                                        past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[2], duedate: DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: login_data.information.cid, frequency })

                                    }

                                }
                            }
                        }
                    }
                })
            } else if (frequency === 4) {
                splitArray(betweenMonths, 12).forEach((months) => {
                    if (months.length === 12) {
                        if (endDate__.month === DateTime.fromFormat(months[11], 'LLL-yyyy').month && endDate__.year === DateTime.fromFormat(months[11], 'LLL-yyyy').year) {
                            let check = checkSubmission(item.dcf_, item.site, months[0] + ' to ' + months[11], old)

                            if (check.result) {

                                future_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[11], duedate: DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: login_data.information.cid, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })

                            } else {
                                if (check.data) {
                                    future_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[11], duedate: DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: login_data.information.cid, frequency })

                                }

                            }


                        } else {
                            if (Math.round(endDate__.diff(DateTime.fromFormat(months[11], 'LLL-yyyy'), 'months').months) === 1) {

                                // if (endDate__ <= moment(months[11]).add(1, 'month').add(4, 'days')) {

                                let check = checkSubmission(item.dcf_, item.site, months[0] + ' to ' + months[11], old)

                                if (check.result) {

                                    present_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[11], duedate: DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: login_data.information.cid, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })

                                } else {
                                    if (check.data) {
                                        present_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[11], duedate: DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: login_data.information.cid, frequency })

                                    }

                                }


                            } else {
                                let check = checkSubmission(item.dcf_, item.site, months[0] + ' to ' + months[11], old)

                                if (check.result) {

                                    past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[11], duedate: DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: login_data.information.cid, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })

                                } else {
                                    if (check.data) {
                                        past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[11], duedate: DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: login_data.information.cid, frequency })

                                    }

                                }
                            }
                        }
                    }
                })
            } else if (frequency === 5) {
                splitArray(betweenMonths, 6).forEach((months) => {
                    if (months.length === 6) {
                        if (endDate__.month === DateTime.fromFormat(months[5], 'LLL-yyyy').month && endDate__.year === DateTime.fromFormat(months[5], 'LLL-yyyy').year) {
                            let check = checkSubmission(item.dcf_, item.site, months[0] + ' to ' + months[5], old)

                            if (check.result) {


                                future_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[5], duedate: DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: login_data.information.cid, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                            } else {
                                if (check.data) {
                                    future_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[5], duedate: DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: login_data.information.cid, frequency })

                                }

                            }


                        } else {
                            if (Math.round(endDate__.diff(DateTime.fromFormat(months[5], 'LLL-yyyy'), 'months').months) === 1) {

                                // if (endDate__ <= moment(months[5]).add(1, 'month').add(4, 'days')) {
                                let check = checkSubmission(item.dcf_, item.site, months[0] + ' to ' + months[5], old)

                                if (check.result) {


                                    present_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[5], duedate: DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: login_data.information.cid, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                                } else {
                                    if (check.data) {
                                        present_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[5], duedate: DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: login_data.information.cid, frequency })

                                    }

                                }


                                // } else {
                                //     let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[5]).format('MMM-YYYY'), old)

                                //     if (check.result) {


                                //         past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[5]).format('MMM-YYYY'), duedate: moment(months[5]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[5]).add(1, 'month'), 'days'), site: item.site, company_id: login_data.information.cid, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                                //     } else {
                                //         if (check.data) {
                                //             past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[5]).format('MMM-YYYY'), duedate: moment(months[5]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[5]).add(1, 'month'), 'days'), site: item.site, company_id: login_data.information.cid, frequency })

                                //         }

                                //     }
                                // }
                            } else {
                                let check = checkSubmission(item.dcf_, item.site, months[0] + ' to ' + months[5], old)

                                if (check.result) {


                                    past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[5], duedate: DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: login_data.information.cid, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                                } else {
                                    if (check.data) {
                                        past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[5], duedate: DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: login_data.information.cid, frequency })

                                    }

                                }
                            }
                        }
                    }
                })
            } else if (frequency === 6) {
                splitArray(betweenMonths, 1).forEach((months, ind) => {

                    if (endDate__.month === DateTime.fromFormat(months[0], 'LLL-yyyy').month && endDate__.year === DateTime.fromFormat(months[0], 'LLL-yyyy').year) {
                        let check = checkSubmission(item.dcf_, item.site, months[0], old)

                        if (check.result) {
                            present_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, dcftype: item.dcf_.type, rp: months[0], duedate: DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy'), 'days').days), site: item.site, company_id: login_data.information.cid, frequency })

                            check.list.forEach((list) => {
                                present_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, dcftype: item.dcf_.type, rp: months[0], duedate: DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy'), 'days').days), site: item.site, company_id: login_data.information.cid, frequency, response: list.response, id: list.id, reject: list.reject, draft: list })

                            })

                        } else {
                            if (check.data) {
                                present_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, dcftype: item.dcf_.type, rp: months[0], duedate: DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy'), 'days').days), site: item.site, company_id: login_data.information.cid, frequency })

                            }

                        }


                    } else {
                        let check = checkSubmission(item.dcf_, item.site, months[0], old)

                        if (check.result) {

                            check.list.forEach((list) => {
                                past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0], duedate: DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy'), 'days').days), site: item.site, company_id: login_data.information.cid, frequency, response: list.response, id: list.id })

                            })


                        } else {
                            if (check.data) {
                                past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0], duedate: months[0], overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy'), 'days').days), site: item.site, company_id: login_data.information.cid, frequency })

                            }

                        }


                    }

                })
            }

        }
        console.log(future_)

        setPast((prev) => ([...prev, ...past_]))
        setPresent((prev) => ([...prev, ...present_]))
        setFuture((prev) => ([...prev, ...future_]))



        return { type, months: betweenMonths }
    }
    const checkSubmission = (dcf, site, rp, old) => {
        // let rps = getRP(rp)
        let rps = getRPLuxon(rp)

        let result = {}
        let loc = JSON.parse(JSON.stringify(old))

        let index = loc.findIndex((i) => {
            return compareArrays(i.reporting_period, rps) && i.site === site[0] && i.dcf === dcf.id && i.type === 0
        })
        let index2 = loc.findIndex((i) => {
            return compareArrays(i.reporting_period, rps) && i.site === site[0] && i.dcf === dcf.id && (i.type === 1 || i.type === 3)
        })

        if (index === -1) {

            result = { result: false, data: index2 === -1 ? true : false }
        } else {
            result = {
                result: true, data: loc[index], list: loc.filter((i) => {
                    return compareArrays(i.reporting_period, rps) && i.site === site[0] && i.dcf === dcf.id && i.type === 0
                })
            }
        }
        console.log(old, rps, dcf, site, result)
        return result
    }
    const compareArrays = (a, b) => {


        return JSON.stringify(a) === JSON.stringify(b);
    };
    const splitArray = (array, chunkSize) => {


        return array.reduce((resultArray, item, index) => {
            const chunkIndex = Math.floor(index / chunkSize)

            if (!resultArray[chunkIndex]) {
                resultArray[chunkIndex] = [] // start a new chunk
            }

            resultArray[chunkIndex].push(item)

            return resultArray
        }, [])
    }
    const getRP = (months) => {
        if (months.includes('to')) {
            let startDate = moment(months.split('to')[0].trim())
            let endDate = moment(months.split('to')[1].trim())
            let rp = []
            while (startDate <= endDate) {

                rp.push(startDate.format('MM-YYYY'));
                startDate.add(1, 'month');


            }
            return rp
        } else {
            console.log(months, 'months')
            return [moment(months).format('MM-YYYY')]
        }
    }
    const getDCFTitle = (dcfid) => {
        let index = dcflist.findIndex((i) => { return i.id === dcfid })
        let dcf = 'Not Found'
        if (index !== -1) {
            dcf = dcflist[index].title
        }
        return dcf
    }
    const getRPLuxon = (months) => {
        if (months.includes('to')) {
            let startDate = DateTime.fromFormat(months.split('to')[0].trim(), 'LLL-yyyy')
            let endDate = DateTime.fromFormat(months.split('to')[1].trim(), 'LLL-yyyy')
            let rp = []
            while (startDate <= endDate) {
                rp.push(startDate.toFormat('LL-yyyy'));
                startDate = startDate.plus({ months: 1 })
            }
            return rp
        } else {
            return [DateTime.fromFormat(months, 'LLL-yyyy').toFormat('LL-yyyy')]
        }
    }
    const getRPMonth = (item) => {

        if (item.length !== 0) {
            if (item.length >= 2) {
                const startDate = DateTime.fromFormat(item[0], 'MM-yyyy').toFormat('MMM-yyyy');
                const endDate = DateTime.fromFormat(item[item.length - 1], 'MM-yyyy').toFormat('MMM-yyyy');
                return `${startDate} to ${endDate}`;
            } else {
                return DateTime.fromFormat(item[0], 'MM-yyyy').toFormat('MMM-yyyy');
            }
        }
    }
    const getRPMonthLast = (item) => {

        if (item.length === 1) {
            return DateTime.fromFormat(item[0], 'MM-yyyy')
                .plus({ months: 1 })
                .toLocal()
                .toFormat('MMM-yyyy');
        } else {
            return DateTime.fromFormat(item[item.length - 1], 'MM-yyyy')
                .plus({ months: 1 })
                .toLocal()
                .toFormat('MMM-yyyy');
        }

    }
    const getSubmitter = (userid) => {
        let user = 'Not Found'
        let index = userList.findIndex((i) => { return i.id === userid })
        if (index !== -1) {
            user = userList[index].information.empname
        }
        return user
    }
    const onSiteSelected = (val) => {

        setPendingAP((prev) => [])

        forceUpdate()
        setSelectedSite(val)
    }
    const freezeFilter = () => {
        let stickyElem = document.querySelector(".sticky-div");
        if (stickyElem) {
            let currStickyPos = stickyElem.getBoundingClientRect().top + window.pageYOffset;
            window.onscroll = function () {

                if (window.pageYOffset > currStickyPos) {
                    stickyElem.style.position = "fixed";
                    stickyElem.style.top = "98px";
                    stickyElem.style.background = "#F9F9F9";
                    stickyElem.style.width =
                        document.getElementById("divsize").clientWidth + "px";

                    stickyElem.style.zIndex = 999;
                } else {
                    stickyElem.style.position = "relative";
                    stickyElem.style.top = "initial";
                    stickyElem.style.width = "100%";
                    stickyElem.style.background = "transparent";
                    stickyElem.style.zIndex = 1;
                }
            };
        }
    };
    const search = (str) => {
        setSearchStr(str)
        let val = str.trim().toLowerCase()
        setPendingAP(pendingapbk.filter((i) => { return (getReporter(i.submitted_by).trim().toLowerCase().includes(val) || getRPMonth(i.reporting_period).trim().toLowerCase().includes(val) || getSite(i.site).trim().toLowerCase().includes(val) || getRPMonthLast(i.reporting_period).trim().toLowerCase().includes(val) || getFrequencyText(i.frequencycd).trim().toLowerCase().includes(val) || getDCFTitle(i.dcf).trim().toLowerCase().includes(val)) && (i.site === selectedsite || selectedsite === 0) }))
    }
    const dcfTemplate = (rowData) => {

        return (<span className="cur-pointer text-underline" onClick={() => { rowData.form_type === 2 ? navigate.push({ pathname: "/cf_input_approve/" + rowData.dcf, state: rowData }) : navigate.push({ pathname: "/data_input_approve/" + rowData.dcf, state: rowData }) }}>{getDCFTitle(rowData.dcf)} </span>)
    }
    const siteTemplate = (rowData) => {

        return (<>{getSite(rowData.site)}</>)
    }
    const getSite = (siteid) => {
        let index = sitelist.findIndex((i) => { return i.id === siteid });
        let txt = 'Site Not Found';
        if (index !== - 1) { txt = sitelist[index].name }
        return txt
    }
    const rpTemplate = (rowData) => {
        return <span>{getRPMonth(rowData.reporting_period)}</span>
    }
    const dueTemplate = (rowData) => {
        return <span>{getRPMonthLast(rowData.reporting_period)}</span>

    }
    const odTemplate = (rowData) => {
        return <span>{getOD(rowData.reporting_period)}</span>

    }
    const rpFrequencyTemplate = (rowData) => {

        return <span>{getFrequencyText(rowData.frequencycd)}</span>
    }
    const getFrequencyText = (id) => {

        return frequency_list.find(i => { return i.id === id }).name
    }
    const getOD = (item) => {
        let lastMonth = getRPMonthLast(item)

        return (DateTime.local().diff(DateTime.fromFormat(lastMonth, 'MMM-yyyy'), 'days').toObject().days).toFixed(0)
    }
    const reporterTemplate = (rowData) => {

        return (<>{getReporter(rowData.submitted_by)}</>)
    }
    const getReporter = (rpid) => {
        let text = 'Reporter Not Found'

        let approverIndex2 = userList.findIndex((i) => { return i.id === rpid })
        if (approverIndex2 !== -1) {

            text = userList[approverIndex2].information.empname
        }
        return text
    }
    const actionTemplate = (rowData) => {

        return (

            <i className="pi pi-pencil" onClick={() => { rowData.form_type === 2 ? navigate.push({ pathname: "/cf_input_approve/" + rowData.dcf, state: rowData }) : navigate.push({ pathname: "/data_input_approve/" + rowData.dcf, state: rowData }) }} style={{ fontSize: 14 }}></i>
        )
    }
 
    const sortDCF = (e) => {
        if (e.order === -1) {
            return e.data.sort((a, b) => {
                const dateA = getDCFTitle(a.dcf)
                const dateB = getDCFTitle(b.dcf)
                console.log(dateA, dateB)
                // Compare the parsed dates
                if (dateA < dateB) return -1;
                if (dateA > dateB) return 1;
                return 0;
            })
        } else {
            return e.data.sort((a, b) => {
                const dateA = getDCFTitle(a.dcf)
                const dateB = getDCFTitle(b.dcf)
                console.log(dateA, dateB)
                // Compare the parsed dates
                if (dateA > dateB) return -1;
                if (dateA < dateB) return 1;
                return 0;
            })
        }
    }
    const sortRP = (e) => {

        if (e.order === 1) {
            return e.data.sort((a, b) => {

                let dateA = DateTime.fromFormat(a.reporting_period, 'MM-yyyy');
                let dateB = DateTime.fromFormat(b.reporting_period, 'MM-yyyy');
                if (a.reporting_period.length === 1 ) {
                
                    dateA = DateTime.fromFormat(a.reporting_period[0].trim(), 'MM-yyyy');
                  
                }
                if(b.rp.includes('to') ){
                    dateB = DateTime.fromFormat(b.reporting_period[0].trim(), 'MM-yyyy');
                }

                // Compare the parsed dates
                if (dateA < dateB) return -1;
                if (dateA > dateB) return 1;
                return 0;
            })

        } else {
            return e.data.sort((a, b) => {
                let dateA = DateTime.fromFormat(a.reporting_period, 'MM-yyyy');
                let dateB = DateTime.fromFormat(b.reporting_period, 'MM-yyyy');
                if (a.rp.includes('to') ) {
                
                    dateA = DateTime.fromFormat(a.reporting_period[0].trim(), 'MM-yyyy');

                  
                }
                if(b.rp.includes('to') ){
                    dateB = DateTime.fromFormat(b.reporting_period[0].trim(), 'MM-yyyy');

                }
                // Compare the parsed dates in descending order
                if (dateA > dateB) return -1;
                if (dateA < dateB) return 1;
                return 0;
            })
        }


    }
    const sortOD = (e) => {
        if (e.order === -1) {
            return e.data.sort((a, b) => {
                const dateA = DateTime.fromFormat(a.reporting_period[0], 'MM-yyyy') .plus({ months: 1 })
                .toLocal();
                const dateB = DateTime.fromFormat(b.reporting_period[0], 'MM-yyyy') .plus({ months: 1 })
                .toLocal();

                // Compare the parsed dates
                if (dateA < dateB) return -1;
                if (dateA > dateB) return 1;
                return 0;
            })

        } else {
            return e.data.sort((a, b) => {
                const dateA = DateTime.fromFormat(a.reporting_period[0], 'MM-yyyy') .plus({ months: 1 })
                .toLocal();
                const dateB = DateTime.fromFormat(b.reporting_period[0], 'MM-yyyy') .plus({ months: 1 })
                .toLocal();
                // Compare the parsed dates in descending order
                if (dateA > dateB) return -1;
                if (dateA < dateB) return 1;
                return 0;
            })
        }


    }
    return (
        <div className="bg-smoke font-lato" >
            <div className="col-12">
                <div className="p-3" id='divsize'>
                    <div className="sticky-div">
                        <div className="m-1 fw-7 fs-20 flex justify-content-center mt-2">
                            Approval Overdue ({pendingap.length})
                        </div>
                        <div className="col-12 grid justify-content-between" style={{ marginTop: 10 }}>

                            <div className="col-6 grid  align-items-center"  >
                                <div className="col-2">
                                    <label>Select Site :</label>
                                </div>
                                <div className="col-10">

                                    <Dropdown options={sitelist} optionValue='id' style={{ width: '100%' }} optionLabel='name' value={selectedsite} onChange={(e) => { onSiteSelected(e.value) }} />


                                </div>
                            </div>
                            <div className="col-5 grid  align-items-center justify-content-end">
                                <span className="p-input-icon-left" >
                                    <i className="pi pi-search" />
                                    <InputText placeholder="Search form/site/month/reporter/frequency" style={{ width: 350 }} value={searchstr} onChange={(e) => { search(e.target.value) }} />
                                </span>
                            </div>
                        </div>
                    </div>
                    {selectedsite !== null ? <div>
                        <DataTable className="h-500" value={pendingap.sort((a, b) => { return b.overdue - a.overdue })} scrollable>
                            <Column sortable sortFunction={sortDCF} header='Description' field="dcf" body={dcfTemplate} />
                            <Column header='Site' body={siteTemplate} />
                            <Column field="duedate" header='Reporting Frequency' body={rpFrequencyTemplate} />
                            <Column sortable sortFunction={sortRP} header='Reporting Period' field="reporting_period" body={rpTemplate} />
                            <Column sortable sortFunction={sortOD} field="duedate" header='Due Month' body={dueTemplate} />
                            <Column  field="xyz" header='Overdue Days' body={odTemplate} />
                            <Column header='Submitter' body={reporterTemplate} />
                            <Column header='Action' body={actionTemplate} />
                        </DataTable>
                        {/* {pendingap.sort((a, b) => { return b.overdue - a.overdue }).map((item, ind) => {
                            console.log(item)

                            // navigate.push({ pathname: '/cf_data_input/' + item.dcf.id, state: item })
                            return (
                                <div class='cur-pointer fw-4 fs-16 clr-gray-900 bg-white' onMouseEnter={() => { item.hover = 1; forceUpdate() }} onMouseLeave={() => { item.hover = 0; forceUpdate() }} >

                                    <motion.div whileTap={{ scale: 1 }}
                                        onClick={() => { item.form_type === 2 ? navigate.push({ pathname: "/cf_input_approve/" + item.dcf, state: item }) : navigate.push({ pathname: "/data_input_approve/" + item.dcf, state: item }) }}
                                        transition={{ type: "spring", stiffness: 400, damping: 10 }} style={{
                                            position: 'relative', borderBottom: '1px solid rgba(0,0,0,0.2)',
                                            display: 'flex', padding: 10,
                                            flexDirection: 'column'
                                        }}>
                                        <div className="col-12" style={{ display: 'flex', flexDirection: 'row', padding: 5 }}> <div style={{ padding: 0 }}></div> <div className="ml-1" style={{ padding: 0 }}><span className="fw-7 ">{getDCFTitle(item.dcf)}</span></div></div>


                                        <label className="p-1">Reporting Period : {getRPMonth(item.reporting_period)}</label>



                                        <label className="p-1" >Submitted By : {getSubmitter(item.submitted_by)}</label>
                                        <label className="p-1 fw-7 clr-delete">Due Month  :{getRPMonthLast(item.reporting_period[item.reporting_period.length - 1])}</label>

                                    </motion.div>
                                    {item.hover !== undefined && item.hover === 2 && <motion.div >
                                        <div className="p-card" style={{ background: '#005284', color: 'white', justifyContent: 'center', alignItems: 'center', marginLeft: 10, marginRight: 10, height: 30, display: 'flex', cursor: 'pointer' }}>
                                            Open Form
                                        </div>
                                    </motion.div>}

                                </div>
                            )
                        })

                        } */}
                    </div>
                        :
                        <label style={{
                            justifyContent: 'center',
                            display: 'flex',
                            marginTop: '50px'
                        }}>
                            {selectedsite === null ? <i className="pi pi-spin pi-spinner" style={{ fontSize: 50 }}></i> :
                                'Nothing in Overdue Due'
                            }
                        </label>
                    }
                </div>
            </div>
        
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(ApproverOverdue, comparisonFn);
