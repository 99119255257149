import React, { useEffect, useRef, useState } from "react";
import { API } from "../components/constants/api_url";
import APIServices from "../service/APIService";
import { useSelector } from "react-redux";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { RadioButton } from "primereact/radiobutton";
import { FileUpload } from "primereact/fileupload";
import { Button } from "primereact/button";
import Axios from "axios";
import Swal from "sweetalert2";
import { Tag } from "primereact/tag";


const Snow = () => {
    const selector = useSelector(state => state.user.userdetail)
    const [rawsitelist, setRawSitelist] = useState([]);
    const [locList, setLocList] = useState({ country: [], city: [], location: [] })
    const [submitted, setSubmitted] = useState(false)
    const fileref = useRef(null)
    const [bugreport, setBugReport] = useState({
        desc: '', file: null, reportType: null,
        applicability: { country: 0, city: 0, location: 0 },

    });
    const resetBug = () => {
        setBugReport({
            desc: '', file: null, reportType: null,
            applicability: { country: 0, city: 0, location: 0 },

        })
        setSubmitted(false)
        setLocList({ country: [{ id: 0, name: 'All' }, ...rawsitelist.map(location => ({ name: location.name, id: location.id }))] });
    }
    useEffect(() => {
        console.log(selector)
        const uriString = {
            include: [
                {
                    relation: "locationTwos",
                    scope: { include: [{ relation: "locationThrees" }] },
                },
            ],
        };

        const promise0 = APIServices.get(
            API.LocationOne_UP(selector.clientId) +
            `?filter=${encodeURIComponent(JSON.stringify(uriString))}`
        );

        Promise.all([
            promise0
        ]).then((values) => {
            const shapedSite = values[0].data
                .map((item) => {
                    if (item.locationTwos) {
                        item.locationTwos = item.locationTwos.filter(
                            (locationTwo) =>
                                locationTwo.locationThrees &&
                                locationTwo.locationThrees.length > 0
                        );
                    }
                    return item;
                })
                .filter((item) => item.locationTwos && item.locationTwos.length > 0);
            setRawSitelist(shapedSite)
            console.log(shapedSite)
            setLocList({ country: [{ id: 0, name: 'All' }, ...shapedSite.map(location => ({ name: location.name, id: location.id }))] });
        })

    }, [])
    const updateBugReport = (obj, val) => {
        let local = bugreport
        if (obj === 'applicability') {
            let item = val
            let selected_item = { country: 0, city: 0, location: 0 }
            let country_list = [{ name: 'All', id: 0 }]
            let city_list = [{ name: 'All', id: 0 }]
            let location_list = [{ name: 'All', id: 0 }]
            rawsitelist.forEach((country) => {
                country_list.push({ name: country.name, id: country.id })
                if (country.id === item.country || item.country === 0) {
                    if (country.locationTwos) {
                        country.locationTwos.forEach((city) => {
                            city_list.push({ name: city.name, id: city.id })
                            if (city.id === item.city || item.city === 0) {
                                if (city.locationThrees) {
                                    city.locationThrees.forEach((site) => {
                                        location_list.push({ name: site.name, id: site.id })

                                    })
                                }
                            }
                        })

                    }

                }

            })
            setLocList((prev) => ({ ...prev, 'country': country_list, 'city': city_list, 'location': location_list }))

        }
        local[obj] = val
        setBugReport((prev) => ({ ...prev, ...local }))
    }
    function getLocationString(hierarchy, selection) {
        const { country, city, location } = selection;

        if (country === 0) {
            return "Corporate";
        }

        const countryObj = hierarchy.find(c => c.id === country);
        if (!countryObj) {
            return "Invalid country";
        }

        const countryName = countryObj.name;

        if (city === 0) {
            return `${countryName} > All > All`;
        }

        const cityObj = countryObj.locationTwos.find(l => l.id === city);
        if (!cityObj) {
            return `${countryName} > Invalid city`;
        }

        const cityName = cityObj.name;

        if (location === 0) {
            return `${countryName} > ${cityName} > All`;
        }

        const locationObj = cityObj.locationThrees.find(l => l.id === location);
        if (!locationObj) {
            return `${countryName} > ${cityName} > Invalid location`;
        }

        const locationName = locationObj.name;

        return `${countryName} > ${cityName} > ${locationName}`;
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        setSubmitted(true)

        if (bugreport.reportType !== null && bugreport.desc.trim().length) {
            setSubmitted(false)
     
            const response = await APIServices.get(API.SERVICE_NOW_TOKEN_URL)
            if (response.status === 200) {
                const desc = `Name: ${selector.information.empname}. Email: ${selector.email}. Location:${getLocationString(rawsitelist, bugreport.applicability)}.   ${bugreport.desc}`;

                const access_token = response.data.access_token;
                const ACCESS_TOKEN = localStorage.getItem('token')
                const data = {
                    desc: desc, type: bugreport.reportType,
                    accessToken: access_token
                };
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${ACCESS_TOKEN}`
                    }
                };
                Axios.post(API.SERVICE_NOW_BUG_URL, data, config)
                    .then(response => {
                        Swal.fire({
                            title: 'Bug Reported!',
                            timer: 2000,
                            showConfirmButton: false
                        })
                        if (!bugreport.file) {
                            resetBug()
                            return;
                        }
                        const sys_id = response.data.result.sys_id;
                        const url = API.SERVICE_NOW_UPLOAD;
                        const formData = new FormData();
                        formData.append('file', bugreport.file);
                        formData.append('sys_id', sys_id);
                        const config = {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                                'Authorization': `Bearer ${ACCESS_TOKEN}`
                            }
                        };
                        Axios.post(url, formData, config)
                            .then(response => {
                                resetBug()
                                console.log('File uploaded successfully:', response.data);

                            })
                            .catch(error => {
                                resetBug()
                                console.error('Error uploading file to incident:', error);
                            });
                    })

            }
        }
    };
    return (
        <div className="bg-smoke font-lato">
            <div className="col-12" >
                <div
                    className="col-12 "
                    style={{
                        justifyContent: "center",
                    }}
                >
                    <label className="text-big-one clr-navy flex fs-20 fw-6 flex justify-content-start">

                        Report An Issue
                    </label>
                </div>
                <div className='col-12'>
                    <div className='col-4'>
                        <label className="fw-5 fs-16">Select Country <span className="mandatory mr-2">*</span></label>
                        <Dropdown className='mt-2 mb-2' style={{ width: '100%' }} value={bugreport.applicability.country} options={locList.country} optionLabel="name" optionValue="id"
                            onChange={(e) => { updateBugReport('applicability', { country: e.value, city: 0, location: 0 }); }} placeholder="Select Country"
                        />
                    </div>
                    {bugreport.applicability.country !== 0 &&
                        <div className='col-4'>  <label className="fw-5 fs-16">Select City <span className="mandatory mr-2">*</span></label>
                            <Dropdown className='mt-2 mb-2' style={{ width: '100%' }} value={bugreport.applicability.city} options={locList.city} optionLabel="name" optionValue="id"
                                onChange={(e) => { updateBugReport('applicability', { country: bugreport.applicability.country, city: e.value, location: 0 }); }} placeholder="Select City"
                            />
                        </div>
                    }
                    {bugreport.applicability.country !== 0 && bugreport.applicability.city !== 0 && <div className='col-4'> <label className="fw-5 fs-16">Select Business Unit <span className="mandatory mr-2">*</span></label>
                        <Dropdown className='mt-2 mb-2' style={{ width: '100%' }} value={bugreport.applicability.location} options={locList.location} optionLabel="name" optionValue="id"
                            onChange={(e) => { updateBugReport('applicability', { country: bugreport.applicability.country, city: bugreport.applicability.city, location: e.value }); }} placeholder="Select Site"
                        /></div>}
                </div>
                <div className='col-12'>
                    <div className="col-4">
                        <label className="fw-5 fs-16">Describe your Issue <span className="mandatory mr-2">*</span></label>
                        <InputTextarea className='mt-2 mb-2' style={{ width: '100%', maxWidth: '100%' }} onChange={(e) => { updateBugReport('desc', e.target.value) }} />
                    </div>
                    {!bugreport.desc.trim().length && submitted && <small style={{ color: 'red' }}>
                        Description Of Issue Is Required
                    </small>
                    }
                </div>
                <div className='col-12'>
                    <div className="col-4">
                        <label className="fw-5 fs-16"> Attach File </label>

                        <div className="mt-2 " >
                            {!bugreport.file ?
                                <FileUpload name="demo[]" auto customUpload mode="basic" uploadHandler={(e) => { updateBugReport('file', e.files[0]) }} multiple={false} accept="application/pdf"

                                    removeIcon chooseLabel="Add Attachment" />
                                :
                                <Tag className="status-tag-green">
                                    <div className="flex align-items-center gap-2">

                                        <span className="text-base">{bugreport.file.name}</span>
                                        <i className="pi pi-times text-xs ml-2 cur-pointer" onClick={() => { setBugReport((prev) => ({ ...prev, file: null })) }}></i>
                                    </div>
                                </Tag>
                            }

                        </div>


                        {/* <FileUpload mode="basic" multiple={false}  name="report" className="mt-3 mb-3" accept="image/*" onSelect={(e)=>{ updateBugReport('file',e.files[0])}}  /> */}
                    </div>
                </div>

                <div className='col-12' >
                    <div className="col-4">
                        <label className="fw-5 fs-16">Request Type <span className="mandatory mr-2">*</span> </label>
                        <div className="flex flex-wrap gap-3 mt-3 mb-3">
                            <div className="flex align-items-center m-1">
                                <RadioButton inputId="ingredient1" name="pizza" value="Data Submission" onChange={(e) => updateBugReport('reportType', e.value)} checked={bugreport.reportType === 'Data Submission'} />
                                <label htmlFor="ingredient1" className="ml-2">Data Submission</label>
                            </div>
                            <div className="flex align-items-center m-1">
                                <RadioButton inputId="ingredient2" name="pizza" value="Log in error" onChange={(e) => updateBugReport('reportType', e.value)} checked={bugreport.reportType === 'Log in error'} />
                                <label htmlFor="ingredient2" className="ml-2">Log in error</label>
                            </div>
                            <div className="flex align-items-center m-1">
                                <RadioButton inputId="ingredient3" name="pizza" value="Technical/Others" onChange={(e) => updateBugReport('reportType', e.value)} checked={bugreport.reportType === 'Technical/Others'} />
                                <label htmlFor="ingredient3" className="ml-2">Technical/Others</label>
                            </div>

                        </div>
                    </div>
                </div>
                {bugreport.reportType === null && submitted &&
                    <small style={{ color: 'red' }}>
                        Request Type is required
                    </small>

                }
                <div className="col-12 mt-4">
                    {/* <Button label="Submit Report" disabled style={{ width: 150 }} /> */}
                    <Button label="Submit Report" onClick={handleSubmit} style={{ width: 150 }} />
                </div>
            </div>

        </div>
    )
}
const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Snow, comparisonFn);