
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Swal from "sweetalert2";

import { API } from "../../components/constants/api_url";
const initialState = {
    // profile:{id:'12',name:'Venkat',mobno:'0000000'}
    userdetail: {},
    config: [],
    tierLabel: []
}
export const fetchApi = createAsyncThunk('api/fetchApi', async ({ token, id_token }) => {
    return axios.get(API.GetMe, {
        headers: {
            "Authorization": "Bearer " + token,
            "x-id-token": id_token
        }
    }).then((res) => res.data)
})


const setData = (state, action) => {

    let data = action.payload
    if (data.role === 'clientadmin') {
        data.information['cid'] = data.id
        data.clientId = data.id
    }
    
    state.tierLabel = data.tierLabel ? data.tierLabel : ['Country', 'City', 'Site']
    state.userdetail = data;

}

const userProfile = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setLoggedUserDetail: (state, action) => { state.userdetail = action.payload },
        resetLoggedUserDetail: (state) => {
            localStorage.clear();
            sessionStorage.clear(); state.userdetail = {}
        },

    },
    extraReducers: (builder) => {

        builder.addCase(fetchApi.fulfilled, (state, action) => { setData(state, action) })

    }



})

export default userProfile.reducer
export const { setLoggedUserDetail, resetLoggedUserDetail } = userProfile.actions
