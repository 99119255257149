import Axios from "axios";
import moment from "moment";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import useForceUpdate from "use-force-update";
import { API } from "../../../components/constants/api_url";
import LazyView from "../../../components/LazyView";
import { useHistory } from "react-router-dom";
import { TabMenu } from 'primereact/tabmenu';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column"
import { Tooltip } from "primereact/tooltip";
import Swal from 'sweetalert2'
import APIServices from "../../../service/APIService";
import chart, { Chart } from "chart.js/dist/chart";

import { Tag } from "primereact/tag";
import $ from 'jquery'
import ChartDL from "../../../assets/js/chartjsplugin";
import chartTrendline from "chartjs-plugin-trendline";
import { registerables } from "chart.js";

import { Button } from "primereact/button";
Chart.register(chartTrendline);
Chart.register(...registerables);

window.jQuery = $;
window.$ = $;
const { DateTime } = require('luxon')
let activeRowData = {}
const Performance = () => {

    const navigate = useHistory()
    const [data, setData] = useState([])
    const [location, setLocation] = useState({ one: null, two: null, three: null })
    const [locationlist, setLocationList] = useState({ one: null, two: null, three: null })
    const [sitelist, setSiteList] = useState([])
    const login_data = useSelector((state) => state.user.userdetail)
    const client_info = useSelector((state) => state.userlist.admindetail)
    useEffect(() => {
        let uriString2 = {
            "include": [{ "relation": "locationTwos", "scope": { "include": [{ "relation": "locationThrees" }] } }]

        }
        let REQUEST_URL_WITH_ID_2 = API.LocationOne_UP(client_info.id)
        let url_2 = REQUEST_URL_WITH_ID_2 + `?filter=${encodeURIComponent(JSON.stringify(uriString2))}`;
        let dc_names = [], list = []
        APIServices.get(url_2).then((res) => {
            console.log(res)
            setSiteList(res.data)

            let one = [{ name: 'All', id: 0 }], two = [{ name: 'All', id: 0 }], three = [{ name: 'All', id: 0 }]
            res.data.forEach((i) => {
                if (i.locationTwos !== undefined && i.locationTwos.length !== 0) {
                    one.push(i)
                    i.locationTwos.forEach((j) => {
                        if (j.locationThrees !== undefined && j.locationThrees.length !== 0) {
                            two.push(j)
                            j.locationThrees.forEach((k) => {
                                three.push(k)
                                dc_names.push(k.name)
                            })
                        }

                    })
                }
            })
            setLocationList({ one: one, two: two, three: three })
            dc_names.forEach((item) => {
                list.push({ name: item, january: randomDecimal(1, 3), february: randomDecimal(1, 3), march: randomDecimal(1, 3), april: randomDecimal(1, 3), may: randomDecimal(1, 3), june: randomDecimal(1, 3), july: randomDecimal(1, 3), august: randomDecimal(1, 3), september: randomDecimal(1, 3), october: randomDecimal(1, 3), november: randomDecimal(1, 3), december: randomDecimal(1, 3) })
            })
            // {createBarChart('pue',summedData(list))}
            setData(list)
        })

        console.log(list)
        setLocation({ one: 0, two: 0, three: 0 })

    }, [])
    const summedData = (data_)=>{
        return data_.reduce((acc, obj) => {
        Object.keys(obj).forEach(key => {
          if (key !== 'name') {
            acc[key] = (acc[key] || 0) + obj[key];
          }
        });
        return acc;
      }, {});
    }
    
    const createBarChart = (canvasId, dataArray) => {
     
        const ctx = document.getElementById(canvasId).getContext('2d');

        const labels = Object.keys(dataArray[0]).slice(1);
        const datasets = labels.map((month, monthIndex) => ({
          label: month,
          data: dataArray.reduce((acc, obj) => {
            acc.push(obj[month]);
            return acc;
          }, []),
          backgroundColor: `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, 0.2)`,
          borderColor: `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, 1)`,
          borderWidth: 1,
        }));
      
        const chartData = {
          labels: 'PUE',
          datasets: datasets,
        };
      
        const chartOptions = {
          scales: {
            x: {
              stacked: false,
            },
            y: {
              stacked: false,
            },
          },
        };
      
        new Chart(ctx, {
          type: 'bar',
          data: chartData,
          options: chartOptions,
        });
       
      };
    const randomDecimal = (min, max) => {
        return (Math.random() * (max - min) + min).toFixed(2);
    }
    const updateLocation = (obj, val) => {
        let location_loc = location
        let loclist = locationlist
        let dc_names = [], list = []
        if (obj === 'one') {
            let one = [{ name: 'All', id: 0 }], two = [{ name: 'All', id: 0 }], three = [{ name: 'All', id: 0 }]

            sitelist.forEach((i) => {
                if (i.locationTwos !== undefined && i.locationTwos.length !== 0 && (i.id === val || val === 0)) {
                    one.push(i)
                    i.locationTwos.forEach((j) => {
                        if (j.locationThrees !== undefined && j.locationThrees.length !== 0) {
                            two.push(j)
                            j.locationThrees.forEach((k) => {
                                dc_names.push(k.name)
                                three.push(k)
                            })
                        }

                    })
                }
            })
            loclist.three = three
            loclist.two = two
            setLocation({ one: val, two: 0, three: 0 })
            setLocationList(loclist)
        } else if (obj === 'two') {
            let one = [{ name: 'All', id: 0 }], two = [{ name: 'All', id: 0 }], three = [{ name: 'All', id: 0 }]

            sitelist.forEach((i) => {
                if (i.locationTwos !== undefined && i.locationTwos.length !== 0 && (i.id === location_loc.one || location_loc.one === 0)) {
                    one.push(i)
                    i.locationTwos.forEach((j) => {
                        if (j.locationThrees !== undefined && j.locationThrees.length !== 0 && (j.id === val || val === 0)) {
                            two.push(j)
                            j.locationThrees.forEach((k) => {
                                dc_names.push(k.name)
                                three.push(k)
                            })
                        }

                    })
                }
            })
            loclist.three = three

            setLocation({ one: location_loc.one, two: val, three: 0 })
            setLocationList(loclist)


        } else if (obj === 'three') {
            let one = [{ name: 'All', id: 0 }], two = [{ name: 'All', id: 0 }], three = [{ name: 'All', id: 0 }]

            sitelist.forEach((i) => {
                if (i.locationTwos !== undefined && i.locationTwos.length !== 0 && (i.id === location_loc.one || location_loc.one === 0)) {
                    one.push(i)
                    i.locationTwos.forEach((j) => {
                        if (j.locationThrees !== undefined && j.locationThrees.length !== 0 && (j.id === location_loc.two || location_loc.two === 0)) {
                            two.push(j)
                            j.locationThrees.forEach((k) => {
                                if (k.id === val || val === 0) {
                                    dc_names.push(k.name)
                                    three.push(k)
                                }

                            })
                        }

                    })
                }
            })
            setLocation({ one: location_loc.one, two: location_loc.two, three: val })



        }
        dc_names.forEach((item) => {
            list.push({ name: item, january: randomDecimal(1, 3), february: randomDecimal(1, 3), march: randomDecimal(1, 3), april: randomDecimal(1, 3), may: randomDecimal(1, 3), june: randomDecimal(1, 3), july: randomDecimal(1, 3), august: randomDecimal(1, 3), september: randomDecimal(1, 3), october: randomDecimal(1, 3), november: randomDecimal(1, 3), december: randomDecimal(1, 3) })
        })
        setData(list)
    }

    return (
        <div className="bg-smoke font-lato" >
            <div className="col-12" >
                <div style={{}} >
                    <div className="col-12" style={{
                        justifyContent: 'flex-start'
                    }}>
                        <label className="text-big-one clr-navy flex fs-16 flex justify-content-start"> PUE 
                        </label>

                    </div>

                    <div className="col-12 grid">
                        <div className="col-4">
                            <label className="fw-4 clr-gray-900">Select Country</label>
                            <Dropdown value={location.one} style={{ width: '100%' }} optionLabel="name" optionValue="id" options={locationlist.one} placeholder="Select Location" onChange={(e) => { updateLocation('one', e.value) }} />
                        </div>
                        <div className="col-4">
                            <label className="fw-4 clr-gray-900">Select City</label>
                            <Dropdown value={location.two} style={{ width: '100%' }} optionLabel="name" optionValue="id" options={locationlist.two} placeholder="Select Location" onChange={(e) => { updateLocation('two', e.value) }} />
                        </div>
                        <div className="col-4">
                            <label className="fw-4 clr-gray-900">Select Site</label>
                            <Dropdown value={location.three} style={{ width: '100%' }} optionLabel="name" optionValue="id" options={locationlist.three} placeholder="Select Location" onChange={(e) => { updateLocation('three', e.value) }} />
                        </div>
                    </div>
                    <div className="col-12">
                        <DataTable value={data} scrollable>
                            <Column field="name" header="Name of the Data Center" />
                            <Column field="january" header="January" />
                            <Column field="february" header="February" />
                            <Column field="march" header="March" />
                            <Column field="april" header="April" />
                            <Column field="may" header="May" />
                            <Column field="june" header="June" />
                            <Column field="july" header="July" />
                            <Column field="august" header="August" />
                            <Column field="september" header="September" />
                            <Column field="october" header="October" />
                            <Column field="november" header="November" />
                            <Column field="december" header="December" />



                        </DataTable>
                    </div>
          
                        <div className="col-12 mt-5 p-5">
<img src={require('../../../assets/dummy/pue_chart.jpeg').default} style={{width:'100%'}}/>
                        </div>
                 
                </div>
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Performance, comparisonFn);
